import { local } from "./debug";

const baseUrl = local
  ? "http://127.0.0.1:5000/"
  : "https://main-backend-api-332ea47a673b.herokuapp.com/";

export const saveEmail = async (email) => {
  try {
    console.log("Attempting to save email:", email);
    const url = `${baseUrl}/wpft/api/save_email`;
    console.log("Request URL:", url);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    console.log("Response status:", response.status);
    if (!response.ok) {
      console.error("Failed to save email. Status:", response.status);
    } else {
      console.log("Email saved successfully.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const createCheckOutSesssion = async (token) => {
  try {
    const url = `${baseUrl}/wpft/api/create_checkout_session`;
    const formData = new FormData();
    formData.append("runLocal", local);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const session = await response.json();
    console.log("session", session);

    window.location.href = session.url;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const checkAccess = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/check_access`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data.access;
  } catch (error) {
    console.error("Failed to check access:", error);
    return false;
  }
};

export const loadTweets = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/load_tweets`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data; // Returns an object with { access: boolean, tweets: array }
  } catch (error) {
    console.error("Failed to load tweets:", error);
    return { access: false, tweets: [] }; // Ensure fallback for error scenarios
  }
};
export const adminLoadTweets = async (accessToken) => {
  const url = `${baseUrl}/wpft/api/admin_load_tweets`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data; // Returns the data from the API
  } catch (error) {
    console.error("Failed to load admin tweets:", error);
    return null; // Return null in case of an error
  }
};

export const adminUpdateStatus = async (accessToken, tweetId, status) => {
  const url = `${baseUrl}/wpft/api/admin_update_status`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tweetId, status }), // Send tweetId and status in the body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    return true; // Indicate success
  } catch (error) {
    console.error("Failed to reject tweet:", error);
    return false; // Indicate failure
  }
};
