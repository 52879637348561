// src/pages/TermsOfUse.js
import React from "react";
import styled from "@emotion/styled";

export const TermsOfUse = () => {
  return (
    <TermsContainer>
      <div className="content">
        <h1>Terms of Use</h1>
        <p>
          <strong>Effective Date:</strong> January 20, 2025
        </p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to <strong>WillPayForThis</strong> ("we," "our," "us"). These{" "}
          <strong>Terms of Use</strong> ("Terms") govern your access to and use
          of our website,{" "}
          <a href="https://willpayforthis.com">https://willpayforthis.com</a>{" "}
          ("Site"), and any related services provided by us (collectively, the
          "Service"). By accessing or using our Service, you agree to be bound
          by these Terms. If you do not agree with any part of these Terms, you
          must not use our Service.
        </p>

        <h2>2. Eligibility</h2>
        <p>
          By using our Service, you represent and warrant that you are at least
          18 years old and have the legal capacity to enter into these Terms. If
          you are using the Service on behalf of an organization, you further
          represent and warrant that you have the authority to bind that
          organization to these Terms.
        </p>

        <h2>3. Description of Services</h2>
        <p>
          <strong>WillPayForThis</strong> is a user pain point discovery
          application designed to help product and startup professionals
          identify and analyze potential products to develop. Our Service offers
          both <strong>Paid Features</strong> and <strong>Free Features</strong>
          :
        </p>
        <h3>a. Paid Features</h3>
        <ul>
          <li>
            <strong>Curated Social Media Posts:</strong> Paid users gain access
            to a selection of public social media posts that are curated
            specifically for their interests and needs.
          </li>
          <li>
            <strong>Product and Competitor Analysis:</strong> Tools and insights
            to assist users in discovering new product ideas and improving
            existing products based on competitor analysis.
          </li>
        </ul>

        <h3>b. Free Features</h3>
        <ul>
          <li>
            <strong>Limited Access:</strong> Free users may access a subset of
            the same features available to paid users, including a limited
            number of curated social media posts and basic product discovery
            tools.
          </li>
        </ul>

        <h2>4. Account Registration and Security</h2>
        <p>
          To access certain features of our Service, you may be required to
          create an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account or any other breach of security.
        </p>

        <h2>5. Payment and Subscription Terms</h2>
        <h3>a. Subscription Plans</h3>
        <p>
          We offer various subscription plans for accessing Paid Features.
          Details of the available plans, including pricing, are provided on our
          Site.
        </p>
        <h3>b. Billing and Renewal</h3>
        <ul>
          <li>
            <strong>Billing Cycle:</strong> Subscriptions are billed on a
            recurring basis (e.g., monthly, annually) as per the selected plan.
          </li>
          <li>
            <strong>Automatic Renewal:</strong> Unless you cancel your
            subscription before the end of the current billing period, your
            subscription will automatically renew for the same duration and be
            billed accordingly.
          </li>
          <li>
            <strong>Cancellation:</strong> You may cancel your subscription at
            any time through your account settings. Cancellation will prevent
            future automatic renewals but does not provide refunds for the
            current billing period.
          </li>
        </ul>
        <h3>c. Refund Policy</h3>
        <p>
          All sales are final. We do not offer refunds for any subscriptions or
          purchases made through our Service.
        </p>

        <h2>6. User Responsibilities and Restrictions</h2>
        <p>
          You agree to use the Service only for lawful purposes and in
          accordance with these Terms. You agree not to:
        </p>
        <ul>
          <li>
            <strong>Disallowed Content:</strong> Upload, post, or transmit any
            content that is unlawful, harmful, threatening, abusive, harassing,
            defamatory, vulgar, obscene, invasive of another's privacy, or
            otherwise objectionable.
          </li>
          <li>
            <strong>Intellectual Property Infringement:</strong> Violate or
            infringe upon the intellectual property rights of others.
          </li>
          <li>
            <strong>Interference:</strong> Disrupt or interfere with the
            security or integrity of the Service or its underlying systems.
          </li>
          <li>
            <strong>Automated Access:</strong> Use bots or other automated
            methods to access the Service for any purpose without our express
            written permission.
          </li>
        </ul>

        <h2>7. Intellectual Property</h2>
        <p>
          All content, features, and functionality on the Service, including but
          not limited to text, graphics, logos, images, and software, are the
          exclusive property of <strong>WillPayForThis</strong> or its licensors
          and are protected by intellectual property laws.
        </p>
        <p>
          You are granted a limited, non-exclusive, non-transferable license to
          access and use the Service for your personal, non-commercial use,
          subject to these Terms.
        </p>

        <h2>8. Termination</h2>
        <p>
          We may terminate or suspend your access to the Service immediately,
          without prior notice or liability, for any reason, including if you
          breach these Terms.
        </p>
        <p>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </p>

        <h2>9. Disclaimers and Limitation of Liability</h2>
        <h3>a. Disclaimers</h3>
        <p>
          The Service is provided on an "AS IS" and "AS AVAILABLE" basis without
          any warranties of any kind, express or implied. We disclaim all
          warranties, including but not limited to the implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>
        <h3>b. Limitation of Liability</h3>
        <p>
          In no event shall <strong>WillPayForThis</strong>, its directors,
          employees, partners, agents, suppliers, or affiliates be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from:
        </p>
        <ul>
          <li>
            Your access to or use of or inability to access or use the Service;
          </li>
          <li>Any conduct or content of any third party on the Service;</li>
          <li>Any content obtained from the Service;</li>
          <li>
            Unauthorized access, use, or alteration of your transmissions or
            content.
          </li>
        </ul>

        <h2>10. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold harmless{" "}
          <strong>WillPayForThis</strong> and its licensee and licensors, and
          their employees, contractors, agents, officers, and directors, from
          and against any and all claims, damages, obligations, losses,
          liabilities, costs, or debt, and expenses arising from:
        </p>
        <ul>
          <li>Your use of and access to the Service;</li>
          <li>Your violation of any term of these Terms;</li>
          <li>
            Your violation of any third-party right, including without
            limitation any copyright, property, or privacy right;
          </li>
          <li>Any claim that your content caused damage to a third party.</li>
        </ul>

        <h2>11. Changes to Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, you are no longer authorized to use the
          Service.
        </p>

        <h2>12. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of the jurisdiction in which <strong>WillPayForThis</strong>{" "}
          operates, without regard to its conflict of law provisions.
        </p>

        <h2>13. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us:</p>
        <ul>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:willpayforthis@gmail.com">
              willpayforthis@gmail.com
            </a>
          </li>
          <li>
            <strong>Website:</strong>{" "}
            <a href="https://willpayforthis.com">https://willpayforthis.com</a>
          </li>
        </ul>
      </div>
    </TermsContainer>
  );
};

export default TermsOfUse;

const TermsContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* Allow vertical scrolling */

  h1,
  h2,
  h3 {
    color: #333;
  }

  a {
    color: #1a0dab;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    padding: 10px;

    .content {
      padding: 15px;
    }
  }
`;
