// src/pages/LandingPage.js
import React, { useState } from "react";
import styled from "@emotion/styled";
import { Star, DollarSign, Bell, CheckCircle } from "lucide-react";
import { css, Global, keyframes } from "@emotion/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

import { createCheckOutSesssion, saveEmail } from "../functions/apiFunctions";
import { Tweet } from "../components/TweetContainer"; // Updated import
import { Footer } from "../components/Footer";
import Masonry from "react-masonry-css"; // Import Masonry

// Sample tweet data
const sampleTweets = [
  {
    id: "1866896395363553418",
  },
  {
    id: "1866513178353451435",
  },
  {
    id: "1713718180936659110",
  },
];

export const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(""); // State for validation errors

  // Email validation regex
  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email before submitting
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    saveEmail(email); // Save email to the database

    // Clear error and simulate form submission
    setError("");
    console.log("Email submitted:", email);
    setIsSubmitted(true);
    setEmail("");
  };

  const handlePreorderClick = () => {
    console.log("Preorder button clicked");
    createCheckOutSesssion();
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Helmet>
        <title>Will Pay For This - Launching Soon</title>
        <meta
          name="description"
          content="Be the first to know when willpayforthis.com launches. Discover what people are willing to pay for and get exclusive access to in-demand products and services."
        />
        <link rel="canonical" href="https://www.willpayforthis.com/" />
      </Helmet>
      <Container>
        {/* HERO SECTION */}
        <Hero>
          <HeroContent>
            <LogoWrapper>
              <DollarSign size={48} />
              <LogoText>willpayforthis</LogoText>
            </LogoWrapper>
            <HeroTitle>
              Discover What People
              <GradientText> Will Pay For</GradientText>
            </HeroTitle>
            <HeroSubtitle>
              Your shortcut to market-validated ideas. Secure lifetime access at
              a special pre-launch price!
            </HeroSubtitle>
            <PreorderButton onClick={handlePreorderClick}>
              Preorder Now
            </PreorderButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isSubmitted && (
              <SuccessMessage>
                <CheckCircle size={16} />
                Thank you! We'll notify you when we launch.
              </SuccessMessage>
            )}
          </HeroContent>
          <HeroBackground />
        </Hero>

        {/* EXPLORE SECTION */}
        <ExploreSection>
          <SectionTitle>
            <Star size={24} />
            Sneak Peek: What People Want
          </SectionTitle>
          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {sampleTweets.map((tweet) => (
              <Tweet key={tweet.id} tweet={tweet} />
            ))}
          </StyledMasonry>
        </ExploreSection>

        {/* FEATURES SECTION */}
        <FeaturesSection>
          <SectionTitle>
            <DollarSign size={24} />
            Why Join WillPayForThis?
          </SectionTitle>
          <FeatureGrid>
            <FeatureCard>
              <FeatureIcon>
                <DollarSign size={32} />
              </FeatureIcon>
              <FeatureTitle>How it works</FeatureTitle>
              <FeatureDescription>
                It's pretty simple. People are saying what they want online. We
                accumulate posts in one place and make it easy to explore.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Bell size={32} />
              </FeatureIcon>
              <FeatureTitle>Limited-Time Offer</FeatureTitle>
              <FeatureDescription>
                Preorder now to get lifetime access. After launch, the product
                will be available for a monthly subscription.
              </FeatureDescription>
            </FeatureCard>
            <FeatureCard>
              <FeatureIcon>
                <Star size={32} />
              </FeatureIcon>
              <FeatureTitle>Twitter Product Research</FeatureTitle>
              <FeatureDescription>
                Search through curated Tweets to learn what people are saying
                they want. Use it to validate an idea or come up with new
                products.
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>
        </FeaturesSection>

        {/* VIDEO DEMO SECTION */}
        <VideoSection>
          <SectionTitle>
            <Star size={24} />
            Check Out Our Quick Demo
          </SectionTitle>
          <DemoDescription>
            This short video shows you how WillPayForThis can help you find
            validated product ideas on Twitter.
          </DemoDescription>
          <Video controls>
            {/* 
              You can replace "/demo.mov" with your actual video path 
              or convert the .mov file to .mp4 for broader browser compatibility.
            */}
            <source src="/demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </VideoSection>

        {/* EMAIL SIGNUP SECTION */}
        <EmailSignupSection>
          <StayUpdatedTitle>
            <Bell size={24} />
            Stay Updated
          </StayUpdatedTitle>
          <EmailSignupContent>
            <p>
              Be the first to know when we launch and get exclusive early
              access.
            </p>

            <SignupForm onSubmit={handleSubmit}>
              <SignupInput
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <SignupButton type="submit">
                {isSubmitted ? <CheckCircle size={20} /> : "Notify Me"}
              </SignupButton>
            </SignupForm>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isSubmitted && (
              <SuccessMessage>
                <CheckCircle size={16} />
                Thank you! We'll notify you when we launch.
              </SuccessMessage>
            )}
          </EmailSignupContent>
          <ConsentText>
            By entering your email, you agree to receive updates and newsletters
            from willpayforthis@gmail.com.
          </ConsentText>
        </EmailSignupSection>

        {/* FOOTER */}
        <Footer />
      </Container>
    </>
  );
};

// Masonry Breakpoints
const breakpointColumnsObj = {
  default: 3,
  768: 1, // Single column for screens smaller than 768px
};

// Define Keyframes Before Styled Components
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

// Masonry Styled Component
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;

  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
`;

const Hero = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 2rem;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background: radial-gradient(
      circle at center,
      rgba(99, 102, 241, 0.15) 0%,
      rgba(15, 23, 42, 0) 70%
    );
    animation: ${float} 15s ease-in-out infinite;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #94a3b8;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const PreorderButton = styled.button`
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  color: #f87171;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #10b981;
  margin-top: 1rem;
  font-weight: 600;
`;

const ExploreSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const StayUpdatedTitle = styled.h2`
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
`;

const FeaturesSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIcon = styled.div`
  background: rgba(99, 102, 241, 0.1);
  color: #6366f1;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  color: #94a3b8;
  font-size: 0.875rem;
`;

/* NEW VIDEO DEMO SECTION */
const VideoSection = styled.section`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
`;

const DemoDescription = styled.p`
  color: #94a3b8;
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Video = styled.video`
  width: 100%;
  max-width: 100%;
  border-radius: 0.5rem;
  margin-top: 2rem;
`;

/* EMAIL SIGNUP */
const EmailSignupSection = styled.section`
  max-width: 800px;
  margin: 4rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
`;

const EmailSignupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: #94a3b8;
    margin-bottom: 1.5rem;
  }
`;

const SignupForm = styled.form`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  gap: 1rem;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const SignupInput = styled.input`
  flex: 1;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: box-shadow 0.3s ease;

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

const SignupButton = styled.button`
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ConsentText = styled.p`
  font-size: 0.8rem;
  color: #a1a1aa;
  margin: 1rem 0;
`;

export default LandingPage;
