// components/Tweet.js

import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { debug } from "../functions/debug";

let twitterScriptLoadingPromise = null;

const loadTwitterScript = () => {
  if (twitterScriptLoadingPromise) {
    return twitterScriptLoadingPromise;
  }

  twitterScriptLoadingPromise = new Promise((resolve, reject) => {
    if (window.twttr && window.twttr.widgets) {
      resolve(window.twttr);
      return;
    }

    const existingScript = document.getElementById("twitter-wjs");
    if (existingScript) {
      existingScript.addEventListener("load", () => {
        if (window.twttr && window.twttr.widgets) {
          resolve(window.twttr);
        } else {
          reject(new Error("Twitter widgets loaded but not available."));
        }
      });
      existingScript.addEventListener("error", () =>
        reject(new Error("Failed to load Twitter widgets script."))
      );
      return;
    }

    const script = document.createElement("script");
    script.id = "twitter-wjs";
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.onload = () => {
      if (window.twttr && window.twttr.widgets) {
        resolve(window.twttr);
      } else {
        reject(new Error("Twitter widgets loaded but not available."));
      }
    };
    script.onerror = () =>
      reject(new Error("Failed to load Twitter widgets script."));
    document.body.appendChild(script);
  });

  return twitterScriptLoadingPromise;
};
const TweetWrapper = styled.div`
  margin-bottom: 20px;
  max-width: 550px;
  box-sizing: border-box;
`;

export const Tweet = ({ tweet }) => {
  const tweetRef = useRef(null);

  useEffect(() => {
    let isCancelled = false;

    loadTwitterScript()
      .then((twttr) => {
        if (isCancelled) return;

        const element = tweetRef.current;
        if (element && !element.dataset.rendered) {
          twttr.widgets
            .createTweet(tweet.id, element, {
              theme: "light",
              // Add more options if needed
            })
            .then(() => {
              element.dataset.rendered = "true"; // Prevent re-rendering
            })
            .catch((error) => {
              console.error(`Error creating tweet ${tweet.id}:`, error);
            });
        }
      })
      .catch((error) => {
        console.error("Failed to load Twitter widgets:", error);
      });

    return () => {
      isCancelled = true;
    };
  }, [tweet.id]);

  return (
    <TweetWrapper>
      {debug && <div>{tweet.id}</div>}
      <div ref={tweetRef}></div>
    </TweetWrapper>
  );
};
