// src/components/Footer/Footer.js
import React from "react";
import styled from "@emotion/styled";
import { DollarSign } from "lucide-react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const handleLogoClick = () => {
    // Redirect to home page when logo is clicked
    window.location.href = "/";
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterLogo onClick={handleLogoClick}>
          <DollarSign size={24} />
          <LogoText>willpayforthis</LogoText>
        </FooterLogo>
        <FooterLinks>
          {/* External Link */}
          <FooterExternalLink
            href="https://x.com/josh_bickett/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Message me about questions or provide input
          </FooterExternalLink>

          {/* Internal Links */}
          <FooterInternalLink to="/privacy-policy">
            Privacy Policy
          </FooterInternalLink>
          <FooterInternalLink to="/terms-of-use">
            Terms of Use
          </FooterInternalLink>
          {/* <FooterInternalLink to="/contact">Contact</FooterInternalLink> */}
        </FooterLinks>
      </FooterContent>
      <FooterBottom>
        <Copyright>
          &copy; {new Date().getFullYear()} WillPayForThis. All rights reserved.
        </Copyright>
      </FooterBottom>
    </FooterContainer>
  );
};

/* Styled Components */

const FooterContainer = styled.footer`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem 0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

/* Internal Links using react-router-dom's Link */
const FooterInternalLink = styled(Link)`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #6366f1;
  }
`;

/* External Links using <a> */
const FooterExternalLink = styled.a`
  color: #94a3b8;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #6366f1;
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 1rem auto 0;
  padding: 1rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Copyright = styled.p`
  color: #64748b;
  font-size: 0.875rem;
  text-align: center;
`;
