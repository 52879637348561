import React from "react";
import { DollarSign, CheckCircle, ExternalLink, Twitter } from "lucide-react";
import styled from "@emotion/styled";
import { css, Global, keyframes } from "@emotion/react";
import { Helmet } from "react-helmet-async";

export const PaymentSuccessPage = () => {
  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Helmet>
        <title>Thank You for Your Purchase - Will Pay For This</title>
        <meta
          name="description"
          content="Thank you for preordering willpayforthis.com. We'll notify you when the product launches."
        />
        <link
          rel="canonical"
          href="https://www.willpayforthis.com/payment-success"
        />
      </Helmet>
      <Container>
        <SuccessHero>
          <SuccessContent>
            <LogoWrapper>
              <DollarSign size={48} />
              <LogoText>willpayforthis</LogoText>
            </LogoWrapper>
            <SuccessTitle>
              Thank You for <GradientText>Your Purchase!</GradientText>
            </SuccessTitle>
            <SuccessMessage>
              <CheckCircle size={24} />
              Payment Successful
            </SuccessMessage>
            <SuccessSubtitle>
              Thank you for signing up. We'll notify you by email when the
              product launches. The current target is January 2025.
            </SuccessSubtitle>
            <ButtonGroup>
              <TypeformButton
                href="https://orhm6qrfifl.typeform.com/to/F1UU4Pm0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Share Your Feedback <ExternalLink size={16} />
              </TypeformButton>
              <TwitterButton
                href="https://twitter.com/intent/tweet?text=I%20just%20preordered%20willpayforthis.com%20-%20can%27t%20wait%20to%20see%20what%20people%20are%20willing%20to%20pay%20for!%20%F0%9F%9A%80"
                target="_blank"
                rel="noopener noreferrer"
              >
                Share on Twitter <Twitter size={16} />
              </TwitterButton>
            </ButtonGroup>
          </SuccessContent>
          <SuccessBackground />
        </SuccessHero>
      </Container>
    </>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
`;

const SuccessHero = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 2rem;
`;

const SuccessBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background: radial-gradient(
      circle at center,
      rgba(99, 102, 241, 0.15) 0%,
      rgba(15, 23, 42, 0) 70%
    );
    animation: ${float} 15s ease-in-out infinite;
  }
`;

const SuccessContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

const SuccessTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #10b981;
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 1.25rem;
`;

const SuccessSubtitle = styled.p`
  font-size: 1.25rem;
  color: #94a3b8;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Button = styled.a`
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;
  min-width: 200px;

  &:hover {
    background: #4f46e5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const TypeformButton = styled(Button)`
  background: #6366f1;
`;

const TwitterButton = styled(Button)`
  background: #1da1f2;

  &:hover {
    background: #1a91da;
  }
`;
