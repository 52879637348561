import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    // Navigate to the returnTo path if it exists, else to the current path
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain="willpayforthis.us.auth0.com"
      clientId="Eb7Dv3VfIxHQk2tPJhLXJIR4fkoUcsYH"
      authorizationParams={{
        audience: "https://www.willpayforthis.com",
        redirectUri: window.location.origin, // Keep this as origin
      }}
      onRedirectCallback={onRedirectCallback} // Add this prop
    >
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

reportWebVitals();
