// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";
import { LandingPage } from "./pages/LandingPage";

import { HelmetProvider } from "react-helmet-async";
import { PaymentSuccessPage } from "./pages/PaymentConfirmation";
import { Preorder } from "./pages/Preorder";
import PostLibrary from "./pages/PostLibrary";
import AdminPage from "./pages/AdminPage"; // Import the new AdminPage
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import TermsOfUse from "./pages/TermsOfUsePage";

const DEBUG = false;
function App() {
  return (
    <StyledApp className="App">
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route path="/preorder" element={<Preorder />} />
          <Route path="/library" element={<PostLibrary />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
          <Route path="/terms-of-use" element={<TermsOfUse />} />{" "}
          {/* Terms of Use Route */}
          {/* New Route */}
        </Routes>
      </HelmetProvider>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
