// src/pages/PrivacyPolicy.js
import React from "react";
import styled from "@emotion/styled";

const PrivacyPolicy = () => {
  return (
    <PolicyContainer>
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> January 20, 2025
        </p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to <strong>WillPayForThis</strong> ("we," "our," "us"). We are
          committed to protecting your privacy and ensuring the security of your
          personal information. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you use our
          application.
        </p>

        <h2>2. Information We Collect</h2>
        <h3>Personal Data</h3>
        <ul>
          <li>
            <strong>Authentication Information:</strong>
            <ul>
              <li>
                <strong>Google OAuth:</strong> When you log in using Google
                OAuth, we collect your Google account information, including
                your name, email address, and profile picture.
              </li>
              <li>
                <strong>Email/Password Authentication:</strong> When you
                register or log in using an email and password, we collect your
                email address and password. Passwords are securely hashed and
                stored to protect your account.
              </li>
            </ul>
          </li>
          <li>
            <strong>Preference Data:</strong>
            <ul>
              <li>
                <strong>LLM Conversations:</strong> When you interact with our
                LLM-powered features (e.g., chatbots, virtual assistants), we
                may collect your conversation data, including your preferences,
                interests, and any other information you provide during these
                interactions.
              </li>
            </ul>
          </li>
          <li>
            <strong>Payment Information:</strong> Details related to your
            payments and transactions made through our platform.
          </li>
        </ul>

        <h3>Non-Personal Data</h3>
        <ul>
          <li>
            <strong>Usage Data:</strong> Information about how you access and
            use our application, such as device information, browser type, and
            IP address.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>
            <strong>Authentication:</strong> To verify your identity and provide
            access to your account.
          </li>
          <li>
            <strong>Service Improvement:</strong> To enhance and personalize
            your experience with our application.
          </li>
          <li>
            <strong>Personalization:</strong> To analyze your preference data
            collected from LLM conversations and display tailored social media
            posts through our recommendation algorithms.
          </li>
          <li>
            <strong>Communication:</strong> To send you important updates,
            notifications, and support-related information.
          </li>
          <li>
            <strong>Security:</strong> To protect against unauthorized access
            and ensure the integrity of our services.
          </li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We do <strong>not</strong> sell, trade, or otherwise transfer your
          personal information to outside parties except in the following
          circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Trusted third parties who assist
            us in operating our application, conducting our business, or
            servicing you.
          </li>
          <li>
            <strong>Legal Requirements:</strong> When required by law or to
            protect our rights, property, or safety.
          </li>
        </ul>

        <h2>5. Data Security</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information. Passwords are hashed and stored securely to
          prevent unauthorized access. Additionally, preference data from LLM
          conversations is stored and processed in accordance with
          industry-standard security practices. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee absolute security.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          Depending on your location, you may have the following rights
          regarding your personal data:
        </p>
        <ul>
          <li>
            <strong>Access:</strong> Request access to your personal
            information.
          </li>
          <li>
            <strong>Correction:</strong> Request correction of inaccurate or
            incomplete data.
          </li>
          <li>
            <strong>Deletion:</strong> Request deletion of your personal
            information.
          </li>
          <li>
            <strong>Opt-Out:</strong> Opt-out of receiving marketing
            communications.
          </li>
          <li>
            <strong>Data Portability:</strong> Request the transfer of your data
            to another service.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:willpayforthis@gmail.com">willpayforthis@gmail.com</a>
        </p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <ul>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:willpayforthis@gmail.com">
              willpayforthis@gmail.com
            </a>
          </li>
          <li>
            <strong>Website:</strong>{" "}
            <a href="https://willpayforthis.com">https://willpayforthis.com</a>
          </li>
        </ul>
      </div>
    </PolicyContainer>
  );
};

export default PrivacyPolicy;

const PolicyContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* Allow vertical scrolling */

  h1,
  h2,
  h3 {
    color: #333;
  }

  a {
    color: #1a0dab;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    padding: 10px;

    .content {
      padding: 15px;
    }
  }
`;
