// Library.js
import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";
import { Search, Filter, DollarSign, LogOut } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { Tweet } from "../components/TweetContainer"; // Updated import
import { CustomDropdown } from "../components/CustomDropdown";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { loadTweets } from "../functions/apiFunctions";
import { Footer } from "../components/Footer";

import Masonry from "react-masonry-css"; // Moved import to parent

const PAGE_SIZE = 3;

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, delay);
  };
};

const Library = () => {
  const { betaCode } = useParams();
  const { logout, isAuthenticated, getAccessTokenSilently, isLoading } =
    useAuth0(); // Removed 'user' as it's no longer needed for admin logic

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [allTweets, setAllTweets] = useState([]);
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [hasAccess, setHasAccess] = useState(null);
  const [accessLoading, setAccessLoading] = useState(true);

  const navigate = useNavigate();
  const loadMoreRef = useRef(null);
  const containerRef = useRef(null);
  const searchBarRef = useRef(null);

  // Handle input changes for search term
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  // Load tweets from API
  const performLoadTweets = async () => {
    try {
      if (!isAuthenticated) {
        setHasAccess(false);
        setAccessLoading(false);
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const { access, tweets } = await loadTweets(accessToken);
      setHasAccess(access);

      if (access && tweets) {
        setAllTweets(tweets);
      }

      setAccessLoading(false);
    } catch (err) {
      console.error("Error during loading tweets:", err);
      setHasAccess(false);
      setAccessLoading(false);
    }
  };

  useEffect(() => {
    performLoadTweets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  // Update unique categories when allTweets changes
  useEffect(() => {
    const categories = Array.from(
      new Set(
        allTweets
          .filter((tweet) => tweet.category) // Only tweets with a truthy category
          .map((tweet) => tweet.category)
      )
    ).sort((a, b) => a.localeCompare(b));
    setUniqueCategories(categories);
  }, [allTweets]);

  // Apply filters whenever searchTerm, selectedCategory, selectedType, or allTweets changes
  useEffect(() => {
    const term = (searchTerm || "").toLowerCase();

    const newFiltered = allTweets.filter((tweet) => {
      const matchesContent = term
        ? tweet.content?.toLowerCase()?.includes(term) // Use optional chaining
        : true;
      const matchesCategory = selectedCategory
        ? tweet.category?.toLowerCase() === selectedCategory.toLowerCase() // Use optional chaining
        : true;
      const matchesType =
        selectedType === "" ? true : tweet.type === Number(selectedType);
      return matchesContent && matchesCategory && matchesType;
    });

    setDisplayedTweets(newFiltered);
    setLimit(PAGE_SIZE);
  }, [searchTerm, selectedCategory, selectedType, allTweets]);

  // Debounced Intersection Handler
  const handleIntersection = useCallback(
    debounce((entry) => {
      if (entry.isIntersecting && !loading) {
        setLoading(true);
        setTimeout(() => {
          setLimit((prevLimit) => prevLimit + PAGE_SIZE);
          setTimeout(() => setLoading(false), 1000);
        }, 250);
      }
    }, 600),
    [loading]
  );

  // Scroll "Load More"
  useEffect(() => {
    if (!containerRef.current) return;
    if (limit >= displayedTweets.length) return;

    const observerOptions = {
      root: containerRef.current,
      threshold: 0.5,
      rootMargin: "0px 0px 100px 0px",
    };

    const observer = new IntersectionObserver(([entry]) => {
      handleIntersection(entry);
    }, observerOptions);

    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [limit, displayedTweets, loading, handleIntersection]);

  // Back to Top
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (container.scrollTop > 300) setShowBackToTop(true);
      else setShowBackToTop(false);
    };
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  // Define category options dynamically
  const categoryOptions = ["All Categories", ...uniqueCategories];

  // Define type options with value and label
  const typeOptions = [
    { value: "", label: "All Types" },
    { value: "1", label: "Would pay for product" },
    { value: "2", label: "General Product Request" },
    { value: "3", label: "Community Engagement Opportunity" },
    { value: "4", label: "Market Research Insight" },
    { value: "5", label: "Pain Point Description" },
    { value: "6", label: "Partnership Opportunity" },
    { value: "7", label: "Success Story/Proof of Concept" },
  ];

  // Render
  const paginatedTweets = displayedTweets.slice(0, limit);

  if (accessLoading) {
    return (
      <>
        <LoadingContainer>
          <LoadingSpinner />
          <LoadingText>Loading Tweets...</LoadingText>
        </LoadingContainer>
      </>
    );
  }

  if (!hasAccess) {
    return (
      <>
        <Global
          styles={css`
            /* Your existing global styles */
          `}
        />
        <AccessDeniedContainer>
          <AccessDeniedMessage>
            Access not available. To get access, pre order{" "}
            <PreorderLink href="https://www.willpayforthis.com/preorder">
              here
            </PreorderLink>
            .
          </AccessDeniedMessage>
        </AccessDeniedContainer>
      </>
    );
  }

  // Masonry Breakpoints
  const breakpointColumnsObj = {
    default: 3,
    768: 1,
  };

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
          body {
            margin: 0;
            padding: 0;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #0f172a;
            color: #f8fafc;
          }
        `}
      />
      <Container ref={containerRef}>
        <Header>
          <LogoWrapper onClick={handleLogoClick}>
            <DollarSign size={32} />
            <LogoText>willpayforthis</LogoText>
            <BetaBadge>Beta</BetaBadge>
          </LogoWrapper>

          {isAuthenticated && (
            <LogoutButton onClick={handleLogout} aria-label="Logout">
              <LogOut size={16} style={{ marginRight: "0.3rem" }} />
              <LogoutText>Logout</LogoutText>
            </LogoutButton>
          )}
        </Header>

        <MainContent>
          <SearchBar ref={searchBarRef}>
            <SearchIcon>
              <Search size={20} />
            </SearchIcon>

            <SearchInput
              type="text"
              placeholder="Search by content..."
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <CategoryContainer>
              <CustomDropdown
                options={categoryOptions}
                value={selectedCategory || "All Categories"}
                onChange={(option) =>
                  setSelectedCategory(option === "All Categories" ? "" : option)
                }
                placeholder="All Categories"
              />
            </CategoryContainer>

            <DesktopTypeContainer>
              <CustomDropdown
                options={typeOptions.map((type) => type.label)}
                value={
                  typeOptions.find((type) => type.value === selectedType)
                    ?.label || "All Types"
                }
                onChange={(selectedLabel) => {
                  const selected = typeOptions.find(
                    (type) => type.label === selectedLabel
                  );
                  setSelectedType(selected ? selected.value : "");
                }}
                placeholder="All Types"
              />
            </DesktopTypeContainer>

            <RecordsCount aria-live="polite">
              {displayedTweets.length} post
              {displayedTweets.length !== 1 ? "s" : ""}
            </RecordsCount>

            <FilterButton aria-label="Filter Tweets">
              <Filter size={20} />
            </FilterButton>
          </SearchBar>

          <MobileTypeContainer>
            <CustomDropdown
              options={typeOptions.map((type) => type.label)}
              value={
                typeOptions.find((type) => type.value === selectedType)
                  ?.label || "All Types"
              }
              onChange={(selectedLabel) => {
                const selected = typeOptions.find(
                  (type) => type.label === selectedLabel
                );
                setSelectedType(selected ? selected.value : "");
              }}
              placeholder="All Types"
            />
          </MobileTypeContainer>

          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {paginatedTweets.map((tweet) => (
              <div style={{ minHeight: "200px" }}>
                <Tweet key={tweet.id} tweet={tweet} />
              </div>
            ))}
          </StyledMasonry>

          {limit < displayedTweets.length && (
            <LoadMoreMarker ref={loadMoreRef}>
              {loading ? (
                <Spinner />
              ) : (
                <LoadingText>Scroll to load</LoadingText>
              )}
            </LoadMoreMarker>
          )}
        </MainContent>

        {showBackToTop && (
          <BackToTopButton onClick={scrollToTop} aria-label="Back to Top">
            ↑ Top
          </BackToTopButton>
        )}
        <Footer />
      </Container>
    </>
  );
};

/* Styled Components */

// Masonry Styled Component
const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;

  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

const Header = styled.header`
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between logo and logout */
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

/* LOGOUT BUTTON */
const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  background: #4b5563; /* Softer gray-blue background */
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 0.6rem; /* Reduced padding for smaller size */
  cursor: pointer;
  font-size: 0.875rem; /* Slightly smaller font */
  transition: background 0.2s, transform 0.2s;

  &:hover {
    background: #6b7280; /* Lighter gray-blue on hover */
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

/* LOGOUT TEXT - NEW STYLED COMPONENT FOR RESPONSIVE DESIGN */
const LogoutText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

/* BETA BADGE */
const BetaBadge = styled.span`
  margin-left: 0.75rem;
  background: #ec4899; /* pink / magenta accent or your brand color */
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.2rem 0.6rem;
  border-radius: 9999px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

/* MAIN CONTENT */
const MainContent = styled.main`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
`;

/* SEARCH BAR */
const SearchBar = styled.div`
  position: relative; /* Ensure relative positioning for absolute children */
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping to keep all elements on one line */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem; /* Reduced gap */

  @media (max-width: 768px) {
    gap: 0.5rem; /* Consistent gap */
  }
`;

const SearchIcon = styled.span`
  color: #94a3b8;
`;

const SearchInput = styled.input`
  flex: 1 1 auto; /* Allow to grow and shrink as needed */
  min-width: 0; /* Prevent overflow */
  background: transparent;
  border: none;
  color: #f8fafc;
  font-size: 1rem;
  outline: none;

  &::placeholder {
    color: #94a3b8;
  }
`;

/* Category Dropdown Container */
const CategoryContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

/* Records Count Display */
const RecordsCount = styled.span`
  flex: 0 0 auto; /* Prevent from growing or shrinking */
  color: #94a3b8;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    margin-left: 0.5rem; /* Reduce margin on mobile */
    font-size: 0.8rem; /* Slightly smaller font on mobile */
  }
`;

/* DESKTOP TYPE SELECT */
const DesktopTypeContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

/* MOBILE TYPE SELECT */
const MobileTypeContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 1.5rem;
  }
`;

/* Filter Button */
const FilterButton = styled.button`
  flex: 0 0 auto; /* Prevent from growing or shrinking */
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #94a3b8;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #6366f1;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Slightly smaller icon on mobile */
  }
`;

/* LOAD MORE MARKER */
const LoadMoreMarker = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

const LoadingText = styled.span`
  color: #94a3b8;
  font-size: 0.9rem;
`;

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 4px solid #94a3b8;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/* BACK TO TOP */
const BackToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  background: #6366f1;
  color: #ffffff;
  border: none;
  border-radius: 9999px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;

  &:hover {
    background: #4f46e5;
  }
`;

/* Access Denied Styles */
const AccessDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0f172a;
  padding: 2rem;
  text-align: center;
`;

const AccessDeniedMessage = styled.div`
  color: #f87171;
  font-size: 1.5rem;
  max-width: 600px;
`;

const PreorderLink = styled.a`
  color: #6366f1;
  text-decoration: underline;
  &:hover {
    color: #8b5cf6;
  }
`;

/* Loading Components */
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  border: 6px solid #94a3b8;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

/* Export the component with authentication required */
export default withAuthenticationRequired(Library);
